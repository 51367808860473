<template>
  <div
    class="tw-date-entry-element !m-0 max-w-full md:!mx-8 md:!mb-8 md:!mt-0 md:w-[376px]"
    :class="{ 'md:max-w-[280px]': !isDropdownHorizontal && !isScrollPickerMode }"
  >
    <div :class="{ 'pointer-events-none': isEditorMode }">
      <template v-if="isDropdownMode || isScrollPickerMode">
        <DateSelect
          v-if="renderDateSelect"
          v-model="selectedDate"
          class="!mb-0 w-full"
          :label-style="labelStyle"
          :hide-labels="!isLabelVisible"
          :min-date="props.config.minValue"
          :max-date="props.config.maxValue"
          :direction="dropdownModeDirection"
          :locale="locale || 'en'"
          :page-options="pageOptions"
          :has-error="hasError"
          :scroll-picker-mode="isScrollPickerMode"
          :show-month-names="showMonthNames"
          :is-u-s-date-format="isUSDateFormat"
        />
      </template>
      <template v-else>
        <WebDatePicker
          v-model="selectedDate"
          class="!mb-0"
          :locale="locale || 'en'"
          :format="USDateModel"
          :model-type="USDateModel"
          :min-date="parseDate(props.config.minValue)"
          :max-date="parseDate(props.config.maxValue)"
          :start-date="parseDate(getCalendarStartDate())"
          :placeholder="datePlaceholder"
          hide-input-icon
          auto-apply
          text-input
          :text-input-options="isUSDateFormat ? textInputOptionsUS : textInputOptions"
          :label-style="labelStyle"
          :label="labelText"
          :page-options="pageOptions"
          :alt-position="() => ({ top: 48, left:0 })"
        />
      </template>
      <!-- Validation warning -->
      <div
        v-if="hasError"
        class="mt-8 text-12 text-error-500"
        :style="{ fontFamily: labelStyle.fontFamily }"
      >
        {{ activeWarning }}
      </div>
    </div>
    <div v-if="$slots.default" class="tw-date-entry-element__bottom mt-16 md:flex md:justify-center">
      <div
        class="tw-slot-wrapper"
        :class="{ 'tw-slot-wrapper--disabled': !canSubmit && !isEditorMode }"
        @[btnClick]="finish"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, ref, computed, toRefs, watch, nextTick, onBeforeMount } from 'vue';
import WebDatePicker from '@shared/components/datepicker/index.vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import DateSelect from './components/date-select.vue';
import { globalEmit } from '@shared/utils/helpers';
import { useTranslate } from '@shared/composable/useTranslate';
import { useLabelOptions } from '@shared/composable/useLabelOptions';
import { parseDate, checkIsBefore, checkIsAfter } from '@shared/elements/dateentry/utils';
import { type QuizAnswer, getPreviousAnswerByQuestionType } from '@shared/utils/getPreviousAnswerByQuestionType';

const { translate } = useTranslate();

const props = defineProps({
  config: { type: Object as PropType<ElementOptions['dateentry']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  locale: { type: String, default: '' },
  element: { type: Object as PropType<ElementType<'dateentry'>>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) }
});

const activeWarning = ref('');
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const { element, pageOptions } = toRefs(props);
const { labelStyle, labelText, isLabelVisible } = useLabelOptions({ element, pageOptions });

const textInputOptions = ref({
  format: ['dd-MM-yyyy', 'dd.MM.yyyy', 'dd/MM/yyyy', 'd-M-yyyy', 'd.M.yyyy', 'd/M/yyyy']
});

const textInputOptionsUS = ref({
  format: ['MM-dd-yyyy', 'MM.dd.yyyy', 'MM/dd/yyyy', 'M-d-yyyy', 'M.d.yyyy', 'M/d/yyyy']
});

const hasError = computed(() => !!activeWarning.value);
const dropdownModeDirection = computed(() => props.config?.mode === 'dropdown-vertical' ? 'vertical' : 'horizontal');
const isDropdownMode = computed(() => ['dropdown-horizontal', 'dropdown-vertical'].includes(props.config?.mode || ''));
const isDropdownHorizontal = computed(() => props.config?.mode === 'dropdown-horizontal');
const isScrollPickerMode = computed(() => props.config?.mode === 'slider');
const showMonthNames = computed(() => !!props.config.showMonthNames);
const countryCode = computed(() => props.pageData.countryCode || '');
const isUSDateFormat = computed(() => !props.isEditorMode && !!props.config.isUSDateFormat && countryCode.value === 'US')
const selectedDate = ref(props.isEditorMode ? '' : formatDateString(props.config.defaultValue || ''));

function formatDateString(selectedDate: string) {
  if (isUSDateFormat.value && !isDropdownMode.value && !isScrollPickerMode.value) {
    const USDate = selectedDate;
    const [month, day, year] = USDate.split(/[-./]/);
    return `${day}-${month}-${year}`
  } 
  return selectedDate;
}

const USDateModel = computed(() => isUSDateFormat.value ? 'MM-dd-yyyy' : 'dd-MM-yyyy');

const datePlaceholder = computed(() => isUSDateFormat.value ? translate('generate.placeholder.dateUSA', props.locale || 'en') : translate('generate.placeholder.date', props.locale || 'en'))

function checkDateInterval() {
  activeWarning.value = '';
  const activeDate = selectedDate.value;
  const minDate = props.config.minValue;
  const maxDate = props.config.maxValue;
  const isAfterMaxDate = activeDate && checkIsAfter(activeDate, maxDate);
  const isBeforeMinDate = activeDate && checkIsBefore(activeDate, minDate);
  const locale = props.locale || 'en';

  if (minDate && maxDate && (isAfterMaxDate || isBeforeMinDate)) {
    activeWarning.value = translate('generate.dateRangeWarning.mustBeBetween', locale, { minDate, maxDate });
    return;
  }
  if (minDate && isBeforeMinDate) {
    activeWarning.value = translate('generate.dateRangeWarning.mustBeAfter', locale, { minDate });
    return;
  }
  if (maxDate && isAfterMaxDate) {
    activeWarning.value = translate('generate.dateRangeWarning.mustBeBefore', locale, { maxDate });
    return;
  }
}

function getCalendarStartDate() {
  const defaultValue = props.config.defaultValue;
  const minDate = props.config.minValue;
  const maxDate = props.config.maxValue;
  if (defaultValue) return defaultValue;
  if (minDate && maxDate) return maxDate;
  if (maxDate) return maxDate;
  if (minDate) return minDate;
  return undefined;
}

const canSubmit = computed(() => {
  if (props.config.required) {
    return !!selectedDate.value;
  }
  return true;
});

function finish(event?: any) {
  checkDateInterval();
  if (!canSubmit.value) return;
  if (hasError.value) return;
  globalEmit('nextQuestion', [event, formatDateString(selectedDate.value)]);
}

function setPreviousAnswer() {
  if (props.pageData?.answers?.length) {
    selectedDate.value = formatDateString(getPreviousAnswerByQuestionType({
      answers: props.pageData?.answers as QuizAnswer[],
      questionType: 'dateentry',
    }));
  }
}

onBeforeMount(() => {
  setPreviousAnswer();
})

/**
 * Force render trick for date-select
 * to sync error state colors with select
 */
const renderDateSelect = ref(true);
watch(() => activeWarning.value, async () => {
  renderDateSelect.value = false;
  await nextTick();
  renderDateSelect.value = true;
});
</script>

<style lang="postcss" scoped>
.tw-date-entry-element {
  :deep(.tw-datepicker) {
    &__menu {
      @apply rounded-8 p-24;
    }
  }
}
</style>
